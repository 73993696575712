jQuery(document).ready( function() {

// keyboard controls for mobile nav
  var cntrlNavLabel = jQuery('#cntrlNavLabel');
  var cntrlNav = jQuery('#cntrlNav');

  cntrlNavLabel.keyup(function(event) {
    event.preventDefault();
    if(event.keyCode === 13) { // enter key pressed
      cntrlNav.prop("checked", !cntrlNav.prop("checked"));
    }
    event.stopPropagation();
  });

  jQuery(document).keyup(function(event) {
     if (event.keyCode == 27 && cntrlNav.prop("checked")) { // escape key maps to keycode `27`
       cntrlNav.prop("checked", !cntrlNav.prop("checked"));
    }
    event.stopPropagation();
  });


// mainNav add button to dropdown
  var menuItems = jQuery('li.menu-item-has-children');
  var timer;
// add a delay to close menu after hover leaves area in case mouse is not precise
  Array.prototype.forEach.call(menuItems, function(el, i) {
  	el.addEventListener("mouseover", function(event){
  		this.classList.add("open");
  		clearTimeout(timer);
  	});
  	el.addEventListener("mouseout", function(event) {
  		timer = setTimeout(function(event) {
  			document.querySelector(".open").classList.remove("open");
  		}, 800);
  	});
  });
// controls for dropdown
  Array.prototype.forEach.call(menuItems, function(el, i) {
  // add a button inside li, after a href
  	var activatingA = el.querySelector('a');
  	var btn = '<button><span><span class="visuallyhidden">show submenu for “' + activatingA.text + '”</span></span></button>';
  	activatingA.insertAdjacentHTML('afterend', btn);
  // listen for click and toggle .open and aria-expanded
  	el.querySelector('button').addEventListener("click",  function(event) {
  		if (this.parentNode.classList.contains("menu-item-has-children")) {
  			this.parentNode.classList.toggle("open");
  			this.parentNode.querySelector('a').setAttribute('aria-expanded', this.getAttribute('aria-expanded') === 'true' ? 'false' : 'true' );
  			this.parentNode.querySelector('button').setAttribute('aria-expanded', this.getAttribute('aria-expanded') === 'true' ? 'false' : 'true' );
  		} else {
  			this.parentNode.querySelector('a').setAttribute('aria-expanded', "false");
  			this.parentNode.querySelector('button').setAttribute('aria-expanded', "false");
  		}
  		event.preventDefault();
  	});
  });

}); // ends document.ready
